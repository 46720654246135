/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Stack,
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  MenuItem,
  Box,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { api } from "service/config";
import { tabandmo } from "styles/media";
import {
  isEmailFormat,
  isMinLength,
  isPhoneFormat,
  isRequired,
} from "utils/vaildation";

interface FormProps {
  type: string;
}

const Form = ({ type }: FormProps) => {
  const formStyle = css`
    margin-top: 20px;
    .title {
      text-align: center;
      h2 {
        font-size: 52px;
        line-height: 68px;
      }
      p {
        margin-top: 20px;
        font-size: 18px;
        line-height: 28px;
      }
    }
    .contents {
      margin-top: 90px;
      .list {
        padding: 24px;
        background: #fdfdfd;
        li {
          h3 {
            font-size: 20px;
            line-height: 30px;
          }
          p {
            font-size: 19px;
            line-height: 30px;
          }
        }
        li + li {
          margin-top: 30px;
        }
      }
      .form {
        display: flex;
        flex-direction: column;
        .terms {
          display: flex;
          width: 100%;
          align-items: center;
          margin-top: 24px;
        }
        .formNotice {
          margin-top: 70px;
          font-size: 16px;
          font-weight: 700;
          padding-bottom: 20px;
          border-bottom: 2px solid #000;
        }
        .inputForm {
          margin-top: 40px;
          max-width: 528px;
        }
        .inputContForm {
          margin-top: 16px;
        }

        button {
          padding: 10px 22px;
          background: #2196f3;
          border: 0;
          border-radius: 4px;
          color: #fff;
          font-size: 15px;
          line-height: 26px;
          margin-top: 62px;
          margin-left: auto;
          cursor: pointer;
        }
      }
    }
    ${tabandmo} {
      padding-bottom: 80px;
      .title {
        text-align: center;
        h2 {
          font-size: 32px;
          line-height: 48px;
        }
        p {
          margin-top: 20px;
          font-size: 16px;
          line-height: 24px;
        }
      }
      .contents {
        margin-top: 12px;
        .list {
          padding: 12px;
          li {
            h3 {
              font-size: 16px;
              line-height: 24px;
            }
            p {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
      .info {
        .list {
          flex-direction: column;
          li + li {
            margin-left: 0;
            margin-top: 14px;
          }
        }
      }
    }
  `;

  const currencies = [
    {
      value: "1",
      label: "커머스 도입 문의",
    },
    {
      value: "2",
      label: "애드머스&지니펀 문의",
    },
    {
      value: "3",
      label: "문자 광고 문의",
    },
    {
      value: "4",
      label: "마케팅/광고대행/프로모션 등",
    },
    {
      value: "5",
      label: "기타 제휴 및 일반 문의",
    },
  ];

  const [company, setCompany] = useState("");
  const [term, setTerm] = useState("N");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [field, setField] = useState(type);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [submit, setSubmit] = useState(false);

  const validation = () =>
    isRequired(name) &&
    isPhoneFormat(phone) &&
    isEmailFormat(email) &&
    isMinLength(title, 5) &&
    isMinLength(content, 100);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSubmit(true);

    if (term !== "Y") {
      window.alert("개인정보 이용에 동의해주세요");
      return;
    }

    let createQuery = `mutation { create_item (board_id: 4300636478, group_id: "emailed_____", item_name: "[지니웍스 (Genieworks) - 모바일 플랫폼의 트렌드를 바꾸다!] 제휴문의 - 공통에 새 응답이 접수되었습니다.", column_values: "{\\"date4\\":\\"${moment().format(
      "YYYY-MM-DD"
    )}\\"}") { id }}`;

    if (validation()) {
      api
        .post(
          "https://api.monday.com/v2",
          JSON.stringify({
            query: createQuery,
          }),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjMwMDk0Nzk3NCwiYWFpIjoxMSwidWlkIjozMTk3ODcyMSwiaWFkIjoiMjAyMy0xMi0wNlQwNjo1ODo1OC44MDFaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTI2MzQxNjksInJnbiI6InVzZTEifQ.0vo6rqIo1FaAnNw7nOAtPfmn5pCkycWsTsWjqG66yc8",
            },
          }
        )
        .then((res) => {
          const updateQuery = `mutation { create_update (item_id: ${
            res.data.data.create_item.id
          }, body: "기업/단체명: ${company}\n 성명: ${name}\n 휴대폰번호: ${phone}\n 이메일: ${email}\n 문의 분야: ${
            currencies.filter((v) => v.value === field)[0].label
          }\n\n 제목: \n${title}\n\n 내용: \n${content}") { id }}`;
          api.post(
            "https://api.monday.com/v2",
            JSON.stringify({
              query: updateQuery,
            }),
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjMwMDk0Nzk3NCwiYWFpIjoxMSwidWlkIjozMTk3ODcyMSwiaWFkIjoiMjAyMy0xMi0wNlQwNjo1ODo1OC44MDFaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTI2MzQxNjksInJnbiI6InVzZTEifQ.0vo6rqIo1FaAnNw7nOAtPfmn5pCkycWsTsWjqG66yc8",
              },
            }
          );
        })
        .then(() => {
          alert("문의 등록이 완료 되었습니다.");
          window.location.reload();
        });
    }
  };

  return (
    <div css={formStyle}>
      <div className="title">
        <h2>문의하기</h2>
        <p>
          지니웍스의 상품, 서비스에 대한 도입 및<br />
          궁금하신 내용을 보내주시면 확인 후 답변드리겠습니다.
        </p>
      </div>
      <div className="contents">
        <ul className="list">
          <li>
            <h3>수집하는 개인정보 항목</h3>
            <p>성명, 휴대전화, 이메일</p>
          </li>
          <li>
            <h3>수집 및 이용목적</h3>
            <p>제휴 문의 사항 접수 및 처리결과 회신</p>
          </li>
          <li>
            <h3>보유 및 이용기간</h3>
            <p>
              목적 달성 후, 해당 개인 정보를 즉시 파기
              <br />※ 관계 법률에 의해 보존할 필요가 있는 경우 일정기간 보존
            </p>
          </li>
        </ul>
        <form onSubmit={handleSubmit} className="form">
          <FormControl className="terms">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="Terms"
              value={term}
            >
              <FormControlLabel
                value="Y"
                control={<Radio />}
                label="개인정보 이용에 동의합니다."
                onChange={(e: any) => setTerm(e.target.value)}
              />
              <FormControlLabel
                value="N"
                control={<Radio />}
                label="동의하지 않습니다."
                onChange={(e: any) => setTerm(e.target.value)}
              />
            </RadioGroup>
          </FormControl>
          <div className="formNotice">*표시는 필수입력 항목입니다.</div>
          <FormControl className="inputForm" fullWidth>
            <Stack spacing={2}>
              <TextField
                label="기업/단체명"
                value={company}
                required
                onChange={(e) => setCompany(e.target.value)}
              />
              <TextField
                label="성명"
                value={name}
                required
                error={!isRequired(name) && submit}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                label="휴대폰 번호"
                value={phone}
                required
                error={!isPhoneFormat(phone) && submit}
                helperText={
                  !isPhoneFormat(phone) &&
                  submit &&
                  "휴대폰 번호를 입력해주세요"
                }
                onChange={(e) => setPhone(e.target.value)}
              />
              <TextField
                label="이메일"
                value={email}
                required
                error={!isEmailFormat(email) && submit}
                helperText={
                  !isEmailFormat(email) &&
                  submit &&
                  "이메일 형식이 맞지 않습니다."
                }
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                select
                label="문의 분야"
                required
                value={field}
                onChange={(e) => setField(e.target.value)}
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </FormControl>
          <FormControl className="inputContForm" fullWidth>
            <Stack spacing={2}>
              <TextField
                label="제목"
                placeholder="최소 5자이상으로 입력해주세요"
                required
                value={title}
                error={submit && !isMinLength(title, 5)}
                helperText={
                  submit &&
                  !isMinLength(title, 5) &&
                  "제목은 최소 5자 이상으로 입력해주세요"
                }
                onChange={(e) => setTitle(e.target.value)}
              />
              <TextField
                label="내용"
                placeholder="문의하실 내용을 기재하여 주시기 바랍니다."
                required
                multiline
                rows={4}
                value={content}
                error={submit && !isMinLength(content, 100)}
                inputProps={{ maxLength: 1000 }}
                helperText={
                  <Box
                    component="span"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "8px",
                    }}
                  >
                    {submit && !isMinLength(content, 100) && (
                      <span>내용은 최소 100자이상 입력해주세요.</span>
                    )}
                    <span
                      style={{ marginLeft: "auto" }}
                    >{`${content.length} / 1000`}</span>
                  </Box>
                }
                onChange={(e) => setContent(e.target.value)}
              />
            </Stack>
          </FormControl>
          <button type="submit">문의 등록</button>
        </form>
      </div>
    </div>
  );
};

export default Form;

import Contents from "components/Common/Contents";
import Layout from "components/Common/Layout";
import PageTitle from "components/Common/PageTitle";
import Section4 from "components/Message/Section4";
import Section1 from "components/Message/Section1";
import Section2 from "components/Message/Section2";
import Section3 from "components/Message/Section3";

import comeImage from "static/marketing/come.png";
import { Helmet } from "react-helmet";

const Message = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          통신 타겟 문자 | 지니웍스 (Genieworks) - 모바일 플랫폼의 트렌드를
          바꾸다!
        </title>
      </Helmet>
      <PageTitle
        titleText="통신사 타켓 문자 광고"
        subTitleText="빅데이터 분석을 활용한 높은 정확도의 타겟팅 문자 광고 발송으로 고객 전환율을 극대화합니다."
        contentsTitleText={`높은 정확도의 타겟팅 문자광고 발송`}
        contentsSubTitleText={`통신사(SKT, LGU+) 고객 중 마케팅 수신에 동의한 고객을 대상으로\n빅데이터 타겟팅 솔루션을 활용하여 전환 확률이 높은 타겟 고객군 추출,\n지정한 마케팅 메시지를 발송하는 똑똑한 타겟 메세징 서비스입니다.`}
        titleImage={comeImage}
        fill="#7C70E9"
      />
      <Contents>
        <Section1 />
        <Section2 />
        <Section3 />
      </Contents>
      <Section4 />
    </Layout>
  );
};

export default Message;
